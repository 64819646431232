import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react'

import Logo from './Logo'

const NotSignedIn = () => (
  <>
    <Logo position="absolute" />
    <AmplifyAuthenticator>
      <AmplifySignIn slot="sign-in" hideSignUp />
    </AmplifyAuthenticator>
  </>
)

export default NotSignedIn
