import { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { onAuthUIStateChange, AuthState } from '@aws-amplify/ui-components'

import isAlreadySignedIn from './utils/isAlreadySignedIn'

export default (authParams = null) => {
  const [alreadySigned, setAlreadySigned] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)
  const [loading, setLoading] = useState(true)

  const checkAuthenticatedUser = async (params) => {
    const configListCopy = [...params]
    let user
    let config

    do {
      config = configListCopy.shift()
      Auth.configure(config)
      try {
        // eslint-disable-next-line no-await-in-loop
        user = await Auth.currentAuthenticatedUser({
          bypassCache: false,
        })
      } catch {
        // eslint-disable-next-line no-empty
      }
    } while (!user && config)

    if (!user && isAlreadySignedIn()) {
      setAlreadySigned(true)
    }

    setCurrentUser(user)
    setLoading(false)
  }

  useEffect(() => {
    if (authParams) {
      checkAuthenticatedUser(authParams?.Auth)
    }

    return onAuthUIStateChange((authState, user) => {
      if (authState === AuthState.SignedIn) {
        setCurrentUser(user)
      } else if (authState === AuthState.SignedOut) {
        setCurrentUser(null)
      }
    })
  }, [authParams])

  return { currentUser, loading, alreadySigned }
}
