import { useEffect, useState } from 'react'

import isValidUrl from '../utils/isValidUrl'

const { searchParams } = new URL(document.location)
const decodedSfTo = decodeURIComponent(searchParams.get('sf-to'))

const SignedIn = ({ defaultRedirect }) => {
  const [postLoginContent, setPostLoginContent] = useState(null)
  const redirectUrl = isValidUrl(decodedSfTo) ? decodedSfTo : defaultRedirect

  useEffect(() => {
    if (redirectUrl) {
      window.location.replace(redirectUrl)
    } else {
      // eslint-disable-next-line no-console
      console.error('automatic redirect url not found')
      setPostLoginContent('Logged In')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return postLoginContent
}

export default SignedIn
