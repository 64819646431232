import { useEffect, useState } from 'react'

export default (configUrl) => {
  const [authParams, setAuthParams] = useState(null)

  useEffect(() => {
    async function getAuthenticationConfig() {
      try {
        const response = await fetch(configUrl)
        const config = await response.json()
        setAuthParams(config)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error on retrieving auth params', error)
      }
    }

    getAuthenticationConfig()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return authParams
}
