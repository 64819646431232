function isValidProtocol({ protocol = null }) {
  return protocol === 'http:' || protocol === 'https:'
}

const getDomainFromUrl = ({ hostname = '' }) => {
  const splittedHostname = hostname.split('.')
  const splittedPrimaryDomain = splittedHostname.slice(Math.max(splittedHostname.length - 2, 0))
  return splittedPrimaryDomain.join('.')
}

function isValidDomain(url) {
  let incomingDomain
  let currentDomain

  try {
    incomingDomain = getDomainFromUrl(url)
    currentDomain = getDomainFromUrl(new URL(window.location))
  } catch (_) {
    return false
  }

  return incomingDomain === currentDomain
}

function isValidUrl(string) {
  let url

  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return isValidProtocol(url) && isValidDomain(url)
}

export default isValidUrl
