import { NotSignedIn, SignedIn, AlreadySignedIn } from './components'
import useAuthParams from './useAuthParams'
import useAuthCheck from './useAuthCheck'

const App = () => {
  const authParams = useAuthParams(process.env.REACT_APP_AUTH_CONFIG_URL)

  const { currentUser, loading, alreadySigned } = useAuthCheck(authParams)

  if (loading) {
    return null
  }

  if (alreadySigned) {
    return <AlreadySignedIn />
  }
  const configList = [...authParams?.Auth]
  const firstConfig = configList.shift()
  const redirectUrl = firstConfig?.defaultRedirect
  return currentUser
    ? <SignedIn defaultRedirect={redirectUrl} />
    : <NotSignedIn />
}

export default App
