import PropTypes from 'prop-types'

import logoPng from './logo.png'
import './Logo.css'

const Logo = ({ position }) => {
  const classes = `logo ${position === 'absolute' ? 'absoluteLogo' : ''}`

  return (
    <div className={classes}>
      <img src={logoPng} alt="Logo" width={240} />
    </div>
  )
}

Logo.propTypes = {
  position: PropTypes.oneOf(['absolute']),
}

Logo.defaultProps = {
  position: null,
}

export default Logo
