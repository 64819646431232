import deleteAllCookies from '../../utils/deleteAllCookies'
import Logo from '../Logo'
import './AlreadySignedIn.css'

const AlreadySignedIn = () => (
  <div className="container">
    <div className="grid">
      <Logo />
    </div>
    <div className="grid">
      <p className="message">
        Already Logged on another client. Please disconnect.
      </p>
    </div>
    <div className="grid">
      <button type="button" onClick={deleteAllCookies} className="button">
        Disconnect
      </button>
    </div>
  </div>
)

export default AlreadySignedIn
